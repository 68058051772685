import dayjs from "dayjs"
import { graphql, Link, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import * as styles from "./RelatedPressItems.module.css"

const RelatedPressItems = ({ relatedPressItems }) => {
  const { pressReleases } = useStaticQuery(
    graphql`
      query {
        pressReleases: allWpPressRelease(
          limit: 3
          sort: { fields: date, order: DESC }
        ) {
          nodes {
            title
            slug
            excerpt
            date
            databaseId
          }
        }
      }
    `
  )
  const relatedPressNodes = !!relatedPressItems?.length
    ? relatedPressItems
    : pressReleases.nodes

  return (
    <div className="Grid with-12">
      {relatedPressNodes.map(press => {
        return (
          <div
            key={`related-press-${press.databaseId}`}
            className="is-4 is-6--medium is-12--small"
          >
            <article
              id={`post-${press.databaseId}`}
              className={`${styles.Content}`}
            >
              <Link to={`/press-releases/${press.slug}`}>
                <div>
                  <div className="Content-text">
                    <header className="Content-header">
                      <div className={styles.ContentDate}>
                        {dayjs(press.date).format("MMMM DD, YYYY")}
                      </div>
                      <h3 className={styles.ContentTitle}>{press.title}</h3>
                      <div className="u-textColor--default">
                        {parse(press.excerpt)}
                      </div>
                    </header>
                  </div>
                </div>
              </Link>
            </article>
          </div>
        )
      })}
    </div>
  )
}

export default RelatedPressItems
