import dayjs from "dayjs"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import Footer from "../components/Footer/footer"
import Header from "../components/header"
import Layout from "../components/layout"
import RelatedPressItems from "../components/RelatedPostItems/RelatedPressItems"
import Seo from "../components/seo"
import { StoreContext } from "../store"
import * as styles from "./PressRelease.module.css"

const PressRelease = ({ data, location }) => {
  const { title, excerpt, content, date } = data.wpPressRelease
  const relatedPressReleases =
    data.wpPressRelease.relatedPressReleases?.relatedPressReleases || []
  const { viewPortWidth } = React.useContext(StoreContext)

  return (
    <Layout>
      <Seo pageData={data.wpPressRelease} location={location} />
      <div className="Page">
        <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />
        <div className="Page-content">
          <section className="Body">
            <div className="Wrap">
              <div className="body-content">
                <div className="Grid with-12 has-xSmallGaps">
                  {/* Breadcrumb */}
                  <div
                    className={`is-2 is-12--small ${styles.BreadcrumbContainer}`}
                  >
                    <Link to="/company/newsroom" className={styles.Breadcrumb}>
                      Press
                    </Link>
                  </div>

                  <div className={`is-8 is-12--small ${styles.ContentWrap}`}>
                    <div className={`${styles.Content}`}>
                      {/* Title */}
                      <h1 className={`bold ${styles.Title}`}>{title}</h1>

                      {/* Subtitle */}
                      <div className="u-paddingBottom--xSmall">
                        <i>{parse(excerpt)}</i>
                      </div>

                      {/* Date & Social Links */}
                      <div className={styles.DateAndSocialContainer}>
                        <time dateTime={date} className={styles.Date}>
                          {dayjs(date).format("MMMM DD, YYYY")}
                        </time>
                        <div className={`social-sharex`}>
                          <ul
                            style={{
                              display: `flex`,
                              listStyle: `none`,
                              padding: 0,
                            }}
                          >
                            <li className="facebook share-button">
                              <FacebookShareButton
                                url={location.href}
                                quote={title}
                              >
                                <FacebookIcon size={41} round />
                              </FacebookShareButton>
                            </li>
                            <li className="linkedin share-button">
                              <LinkedinShareButton url={location.href}>
                                <LinkedinIcon size={41} round />
                              </LinkedinShareButton>
                            </li>
                            <li className="twitter share-button">
                              <TwitterShareButton
                                url={location.href}
                                title={title}
                              >
                                <TwitterIcon size={41} round />
                              </TwitterShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* Content */}
                      <div className="Body-content">{parse(content)}</div>
                    </div>
                  </div>
                  {/* Spacer */}
                  <div className={`is-2 is-12--small`}></div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* Related Press */}
        <div className={styles.RelatedPressContainer}>
          <div className="Wrap">
            <h2 className={`bold ${styles.Title}`}>Related Press</h2>
            <RelatedPressItems relatedPressItems={relatedPressReleases} />
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export default PressRelease

export const query = graphql`
  query ($id: String!) {
    wpPressRelease(id: { eq: $id }) {
      id
      title
      content
      date
      excerpt
      relatedPressReleases {
        relatedPressReleases {
          ... on WpPressRelease {
            title
            date
            excerpt
            slug
            databaseId
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
  }
`
